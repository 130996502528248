<template>
  <CButton color="primary" class="" :to="`/case/details/${data.id}`">
    View File
  </CButton>
</template>
<script>
export default {
  name: "ActionButton",
  methods: {},
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
